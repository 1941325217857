<template>
    <div class="marquee">
        <div class="marquee__content">
            <span style="font-weight: bold;">Stay dry and warm, schedule your roof replacement now to still have it done this year!</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarqueeText',
    metaInfo: {
        title: 'Roofing Services | Homes Roofing Co. | Niles, MI',
        meta: [
            {
                name: 'Homes Scrolling Text Component',
                content: 'Get high-quality roofing services from Homes Roofing in Niles, Michigan. Our experienced team provides expert installation, repair, and maintenance services for residential and commercial properties. Contact us today for a free estimate.',
            },
        ],
    },
    data(){
        return{
            marqueeEnabled: true
        }
    }
}
</script>

<style>
.marquee {
    background-color: #212121;
    padding: 1rem;
}

.marquee__content {
    display: inline-block;
    color: white;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.1); opacity: 0.5; }
    100% { transform: scale(1); opacity: 1; }
}
</style>